import React from 'react'
import Link from 'gatsby-link';

import './css/consultoria.scss';
import Layout from "../components/layout";
import SEO from "../components/seo";

import Consult from '../images/index/solutions_salesforce.svg';
import Consult2 from '../images/index/solutions_restaurant.svg';

import Costs from '../images/consultoria/money.png';
import Database from '../images/consultoria/database.png';
import CloudData from '../images/consultoria/database-cloud.png';

const consultoria = () => (
    <Layout>
        <div class="main" role="main">
        <SEO title="Consultoria" description="Sua produtividade em boas mãos." keywords={[`consultoria`, `treinamentos`]} />
            <div class="main-img ui-hero hero-lg hero-center hero-svg-layer-2 bg-indigo bg-web ui-tilt hero-svg-layer svg-layer-3">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-6" data-vertical_center="true" data-vertical_offset="16">
                            <h1 class="heading animate" data-show="fade-in-up-big" data-delay="100">
                                Sua <span className="text-green-sc">Produtividade</span> em Boas Mãos
                            </h1>
                            <p class="paragraph animate" data-show="fade-in-up-big" data-delay="400">
                               Nós da <strong>System Code</strong> idenficamos a sua necessidade, elaboramos um programa especializado e
                                construimos uma solução em conjunto com o seu negócio.
                            </p>
                        </div>
                        <div class="col-sm-6 animate" data-show="fade-in-left" data-delay="400">
                            <img src={Consult} alt="Applify - App Landing Page HTML Template"
                                 data-uhd  data-max_width="577" class="responsive-on-sm img-index" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="section ui-showcase-section">
                <div class="container">
                    <div class="row">
                        <div className="col-4 col-sm-5 col-md-6 col-lg-7 animate" data-show="fade-in-right">
                            <img src={Consult2}
                                 class="img-feature" data-uhd data-max_width="547"/>
                        </div>
                        <div class="col-8 col-sm-7 col-md-6 col-lg-5" data-vertical_center="true">
                            <ul class="ui-icon-blocks ui-blocks-v icons-lg">
                                <li class="ui-icon-block">
                                    <span class="icon icon-earphones-alt"></span>
                                    <h5>Apoio Personalizado</h5>
                                    <p>
                                        Temos profissionais voltados especialmente para a dinâmica de sua organização,
                                        tudo para melhorar seu desenpenho.
                                    </p>
                                </li>
                                <li class="ui-icon-block">
                                    <span class="icon fa fa-code"></span>
                                    <h5>Aprenda Com Quem Realmente Sabe</h5>
                                    <p>
                                        Possuímos o know-how em diversas tecnologias, o ideal para você e seu negócio absorver
                                        todo o conhecimento, de forma clara, didática e simplista.
                                    </p>
                                </li>
                                <li class="ui-icon-block">
                                    <span class="icon fa fa-sitemap"></span>
                                    <h5>As Melhores Práticas do Mercado</h5>
                                    <p>
                                        Nossas orientações nas melhores práticas mundiais do mercado.
                                    </p>
                                </li>
                                <li class="ui-icon-block">
                                    <span class="icon fa fa-line-chart"></span>
                                    <h5>Aumente Seus Resultados</h5>
                                    <p>
                                        Com a nossa consultoria você terá uma vantagem competitiva, capaz de trazer inovações e conhecimentos.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {/*<div className="section bg-light">
                <div className="container ui-icon-blocks ui-blocks-h icons-md">
                    <div className="section-heading center">
                        <h2 className="heading text-indigo">
                            O Que Oferecemos
                        </h2>
                        <p>
                            Conheça as principais consultorias que prestamos.
                        </p>
                    </div>
                    <div className="row animate" data-show="fade-in" >
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon fa fa-cloud-upload text-lime"></div>
                            <h5>Backup em Nuvem </h5>
                            <p>
                                Backup é obrigatório para seu negócio, sabendo disso oferecemos uma consultoria no que há de melhor,
                                tudo feito pensado no seu negócio.
                            </p>
                        </div>
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon icon-settings text-pink"></div>
                            <h5>Manuntenção Corretiva e/ou Preventiva</h5>
                            <p>
                                Manutenções são necessárias para te manter atualizado e ter o seu negócio sob controle.
                            </p>
                        </div>
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon icon-diamond text-blue"></div>
                            <h5>Projetos Personalizados</h5>
                            <p>
                                Nosso trabalho começa com o entendimento e necessidade do seu negócio,
                                para transformá-lo com identidade.
                            </p>
                        </div>
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon icon-lock text-cyan"></div>
                            <h5>Segurança</h5>
                            <p>
                                Consultoria completa para manter e desenvolver a segurança do seu negócio na web.
                            </p>
                        </div>
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon icon-people text-dark"></div>
                            <h5>Treinamento</h5>
                            <p>
                                Treinamos sua equipe para aproveitar o máximo.
                            </p>
                        </div>
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon fa fa-cloud text-orange"></div>
                            <h5>Migração de dados para Nuvem</h5>
                            <p>
                                A transição de dados para a nuvem requer atenção ao planejamento e à estratégia,
                                sabendo disso a System Code está preparada para atualização do negócio.
                            </p>
                        </div>
                    </div>
                </div>
            </div>*/}

            <div id="how-it-works" class="section ui-gradient-dark">
                <div class="container">
                    <div class="section-heading center">
                        <h2 class="heading text-dark-gray">
                            Seu Negócio nas Nuvens
                        </h2>
                        <p class="paragraph">
                            Consultoria completa para migração de seus dados e backup para um sistema seguro e com custos reduzidos.
                        </p>
                    </div>

                    <div class="ui-showcase-blocks ui-steps">
                        <div class="step-wrapper">
                            <span class="step-number ui-gradient-purple"></span>
                            <div class="row">
                                <div class="col-md-6" data-vertical_center="true">
                                    <h4 class="heading text-dark-gray">
                                        Avaliação de custos e necessidades
                                    </h4>
                                    <p class="paragraph">
                                        Nossa equipe conta com profissionais altamente capacitados e constantemente atualizados
                                        para analisar a necessidade dos serviços voltado ao seu negócio.
                                    </p>
                                </div>
                                <div class="col-md-6">
                                    <img class="responsive-on-xs icons-step" src={Costs}data-uhd
                                         alt="Applify - App Landing HTML Template" data-max_width="464"/>
                                </div>
                            </div>
                        </div>
                        <div class="step-wrapper">
                            <span class="step-number ui-gradient-purple"></span>
                            <div class="row">
                                <div class="col-md-6" data-vertical_center="true">
                                    <h4 class="heading text-dark-gray">
                                        Considerando aplicativos e dados
                                    </h4>
                                    <p class="paragraph">
                                        Analisamos seus aplicativos e dados, afim de identificar se a plataforma da nuvem na qual está
                                        ocorrendo a migração terá o suporte necessário.
                                    </p>
                                </div>
                                <div class="col-md-6">
                                    <img class="responsive-on-xs icons-step" src={Database} data-uhd
                                         alt="Applify - App Landing HTML Template" data-max_width="445"/>
                                </div>
                            </div>
                        </div>
                        <div class="step-wrapper">
                            <span class="step-number ui-gradient-purple"></span>
                            <div class="row">
                                <div class="col-md-6" data-vertical_center="true">
                                    <h4 class="heading text-dark-gray">
                                        Adequação de recursos e tipos de nuvem
                                    </h4>
                                    <p class="paragraph">
                                        A nuvem não é igual para todos, um grande benefício é que ela permite configurar sua
                                        implantação da maneira mais adquada pra o seu negócio
                                    </p>
                                </div>
                                <div class="col-sm-6">
                                    <img class="responsive-on-xs icons-step" src={CloudData} data-uhd
                                         alt="Applify - App Landing HTML Template" data-max_width="451"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </Layout>
);

export default consultoria
